<template>
  <el-watermark content="blog.yiban.love" :z-index="-1">
    <el-container class="container">
      <el-header style="text-align: center"><h2>添加博客</h2></el-header>
      <el-main
        ><el-form
          class="form"
          ref="blogForm"
          label-width="100"
          :rules="blogRules"
          :model="blog"
        >
          <!-- 分类 -->
          <el-form-item label="分类">
            <el-cascader
              v-model="blog.categoryId"
              :options="blogCategory"
              :props="{ value: 'id', label: 'title' }"
              placeholder="请选择分类"
            >
            </el-cascader>
          </el-form-item>
          <!-- 标题 -->
          <el-form-item label="标题" prop="title">
            <el-input
              v-model="blog.title"
              placeholder="请输入标题"
              style="width: 50%"
            ></el-input>
          </el-form-item>
          <!-- 内容 -->
          <el-form-item label="内容" prop="content">
            <!-- 需要一个word文档类似的模板，可以直接输出html -->
            <div style="border: 1px solid #ccc">
              <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editorRef"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
              <Editor
                style="height: 350px; overflow-y: hidden"
                v-model="blog.content"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="handleCreated"
              />
            </div>
          </el-form-item>
          <!-- 摘要 -->
          <el-form-item label="摘要">
            <el-input
              v-model="blog.summary"
              placeholder="请输入摘要"
              style="width: 50%"
            ></el-input>
          </el-form-item>
          <!-- 标签 -->
          <el-form-item label="标签">
            <el-input
              v-model="tag"
              placeholder="请输入标签,多个标签用空格隔开"
              style="width: 50%"
            ></el-input>
          </el-form-item>
          <!-- 图片 -->
          <el-form-item label="图片">
            <el-upload
              ref="imgUpload"
              list-type="picture-card"
              :http-request="uploadHandle"
              v-model:file-list="fileList"
              :before-upload="beforeUpload"
              :on-change="onChange"
              :limit="5"
              :on-exceed="onExceed"
            >
              <el-icon><Plus /></el-icon>

              <template #file="{ file }">
                <div>
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url"
                    alt=""
                  />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <el-icon><zoom-in /></el-icon>
                    </span>
                    <!-- <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleDownload(file)"
                  >
                    <el-icon><Download /></el-icon>
                  </span> -->
                    <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file)"
                    >
                      <el-icon><Delete /></el-icon>
                    </span>
                  </span>
                </div>
              </template>
            </el-upload>

            <el-dialog v-model="dialogVisible">
              <img w-full :src="dialogImageUrl" alt="Preview Image" />
            </el-dialog>
          </el-form-item>
          <!-- SEO关键字 -->
          <el-form-item label="SEO关键字">
            <el-input
              v-model="blog.seoKeywords"
              placeholder="请输入SEO关键字"
              style="width: 50%"
            ></el-input>
          </el-form-item>
          <!-- SEO描述 -->
          <el-form-item label="SEO描述">
            <el-input
              v-model="blog.seoDescription"
              placeholder="请输入SEO描述"
              style="width: 50%"
            ></el-input>
          </el-form-item>
          <!-- 发布时间 -->
          <el-form-item label="发布时间">
            <el-date-picker
              v-model="blog.postTime"
              type="datetime"
              placeholder="选择发布时间,默认为提交时间"
              style="width: 50%"
            ></el-date-picker>
          </el-form-item>
          <!-- 是否发布 -->
          <el-form-item label="是否发布">
            <el-switch
              class="switch"
              v-model="blog.isPublished"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>
          <!-- 是否推荐 -->
          <!-- <el-form-item label="是否推荐">
          <el-switch class="switch" v-model="blog.isRecommend" ></el-switch>
        </el-form-item> -->
          <!-- 是否置顶 -->
          <!-- <el-form-item label="是否置顶">
          <el-switch class="switch" v-model="blog.isTop"></el-switch>
        </el-form-item> -->
          <!-- 是否热门 -->
          <!-- <el-form-item label="是否热门">
          <el-switch class="switch" v-model="blog.isHot"></el-switch>
        </el-form-item> -->
          <!-- 访问模式 -->
          <el-form-item label="访问模式">
            <el-radio-group v-model="blog.visitMode">
              <el-radio :label="1">公开</el-radio>
              <el-radio :label="2">密码访问</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- 访问密码 -->
          <el-form-item label="访问密码" v-show="blog.visitMode == 2">
            <el-input
              v-model="blog.visitPassword"
              placeholder="请输入访问密码"
            ></el-input>
          </el-form-item>
          <!-- 操作按钮 -->
          <el-form-item>
            <el-button type="primary" class="submit" round @click="addBlog"
              >发布</el-button
            >
          </el-form-item>
        </el-form></el-main
      >
    </el-container>
  </el-watermark>
</template>

<script setup>
import axios from "axios";
import { ElMessage, ElMessageBox } from "element-plus";
import "@wangeditor/editor/dist/css/style.css"; // 引入 css

import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { onBeforeUnmount, getCurrentInstance, ref, shallowRef, onMounted } from "vue";
import { Delete, Download, Plus, ZoomIn } from "@element-plus/icons-vue";
import * as qiniu from "qiniu-js";

//编辑器
// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef();
const mode = ref("simple"); // 编辑器模式，可选值为 default、simple、classic

// 内容 HTML
// const valueHtml = ref('<p>hello</p>')

// 模拟 ajax 异步获取内容
// onMounted(() => {
//     setTimeout(() => {
//         valueHtml.value = '<p>模拟 Ajax 异步设置内容</p>'
//     }, 1500)
// })

const toolbarConfig = {};
const editorConfig = {
  placeholder: "请输入内容...",
  MENU_CONF: {
    uploadVideo: {
      server: "/api/upload-video",
      // 上传之前触发
      onBeforeUpload() {
        ElMessage.warning("抱歉，暂不支持从本地上传视频");
        return false;
      },
    },
    uploadImage: {
      server: "/api/upload-image",
      // 上传之前触发
      onBeforeUpload() {
        ElMessage.warning("抱歉，暂不支持从本地上传图片");
        return false;
      },
    },
  },
};

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value;
  if (editor == null) return;
  editor.destroy();
});

const handleCreated = async (editor) => {
  await (async () => {
    editorRef.value = editor;
  })(); // 记录 editor 实例，重要！
};

const blog = ref({
  categoryId: 0,
  title: "",
  content: "",
  summary: "",
  tag: "",
  images: [],
  seoKeywords: "",
  seoDescription: "",
  postTime: null,
  isPublished: 1,
  isRecommend: 0,
  isTop: 0,
  isHot: 0,
  visitMode: 1,
  visitPassword: "",
});
const blogRules = {
  // 标题和内容不能为空
  title: [{ required: true, message: "请输入标题", trigger: "blur" }],
  content: [{ required: true, message: "请输入内容", trigger: "blur" }],
};
const tag = ref("");
const blogCategory = ref([{ id: 0, title: "顶级", pid: 0 }]);
const dialogImageUrl = ref("");
const dialogVisible = ref(false);
const disabled = ref(false);
const fileList = ref([]);
const blogForm = ref(null);
const qiniuData = ref({
  token: null,
  domain: "",
  key: "",
  dir: "",
  config: {
    useCdnDomain: true,
    region: qiniu.region.z2,
  },
});

const handleRemove = (file) => {
  // 删除七牛云上的图片
  const header = {
    "Content-Type": "text/plain;charset=UTF-8",
  };
  axios.post("/api/oss/delete", file.key, { headers: header }).then((res) => {
    if (res.data.code === 200) {
      const index = fileList.value.indexOf(file);
      fileList.value.splice(index, 1);
      ElMessage.success("删除成功");
    } else {
      ElMessage.error("删除失败,请重试");
    }
  });
};
const handlePictureCardPreview = (file) => {
  dialogImageUrl.value = file.url;
  dialogVisible.value = true;
};
const onExceed = () => {
  ElMessage.warning("只能上传5张图片哦~");
};
const onChange = (file, fileList) => {
  // 不让这个文件添加在filelist中
  if (file.status === "ready") {
    fileList.pop();
  }
};

const beforeUpload = (file) => {
  const isJPG = file.type === "image/jpeg";
  const isPNG = file.type === "image/png";
  const isLt4M = file.size / 1024 / 1024 < 4;

  if (!isJPG && !isPNG) {
    ElMessage.error("上传头像图片只能是 JPG/PNG 格式!");
  }
  if (!isLt4M) {
    ElMessage.error("上传头像图片大小不能超过 4MB!");
  }
  return (isJPG || isPNG) && isLt4M;
};
const uploadHandle = async (file) => {
  // 获取token
  if (qiniuData.value.token === null) {
    await getToken();
  }
  //得到key
  const key =
    qiniuData.value.dir + "/" + getUUID() + "." + file.file.name.split(".")[1];
  const observable = qiniu.upload(
    file.file,
    key,
    qiniuData.value.token,
    {},
    qiniuData.value.config
  );
  observable.subscribe({
    next: (res) => {},
    error: (err) => {
      ElMessage.error("上传失败" + err);
    },
    complete: (res) => {
      blog.value.images.push(qiniuData.value.domain + "/" + res.key);
      // filelist中的值是上传成功的图片
      fileList.value.push({
        name: file.file.name,
        key: res.key,
        url: qiniuData.value.domain + "/" + res.key,
      });
      ElMessage.success("上传成功");
    },
  });
};

onMounted(() => {
  getCategoryTree();
});
const getCategoryTree = async () => {
  const { data } = await axios.get("/api/category/listTree");
  blogCategory.value = [...blogCategory.value, ...data.data];
};
const addBlog = async () => {
  await blogForm.value.validate(async (valid) => {
    if (valid) {
      await handleValues();

      const { data } = await axios.post("/api/blog/save", blog.value);
      if (data.code === 200) {
        ElMessage.success("发布成功");
        // 提示框，只有确认按钮，导航去https://blog.yiban.love
        ElMessageBox.confirm("前往博客首页", "提示", {
          confirmButtonText: "确定",
          showCancelButton: false,
          showClose: false,
          type: "warning",
        })
          .then(() => {
            // 不新开窗口
            window.location.href = "https://blog.yiban.love";
          })
          .catch(() => {
            ElMessage.info("请手动前往博客首页");
            // 给class为container的元素添加style
            document.getElementsByClassName("container")[0].style.display =
              "none";
          });
      } else {
        ElMessage.error("发布失败 " + data.msg);
        console.log(data);
      }
    } else {
      ElMessage.error("请检查表单是否填写完整");
      return false;
    }
  });
};
const getToken = async () => {
  const res = await axios.post("/api/oss/getToken");
  // 取出响应头中的authorization
  qiniuData.value.token = res.headers.authorization;
  qiniuData.value.domain = res.data.data.host;
  qiniuData.value.dir = res.data.data.dir;
};
const handleValues = async () => {
  // 将blog.value.tag中的空格替换成::
  if (tag.value !== "") {
    blog.value.tag = ":" + tag.value.replace(" ", "::") + ":";
  }
  // 将blog.value.categoryId中的最后一个id作为blog.value.categoryId
  if (typeof blog.value.categoryId !== "number") {
    blog.value.categoryId =
      blog.value.categoryId[blog.value.categoryId.length - 1];
  }
  // 将blog.value.images转换带[]的字符串'
  if (typeof blog.value.images !== "string") {
    blog.value.images = JSON.stringify(blog.value.images);
  }
  // 如果content的内容是：<p><br></p>，则将其置为空
  if (blog.value.content === "<p><br></p>") {
    blog.value.content = "";
  }
  // 生成默认的postTime
  blog.value.postTime = blog.value.postTime ? blog.value.postTime : new Date();
};
const getUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

</script>

<style>
.container {
  height: 100%;
  width: 80%;
  margin: 0 auto;
}
.submit {
  width: 30%;
  margin: auto auto;
}
.w-e-text-container p {
  line-height: 0.15 !important;
}
.w-e-text-placeholder {
  top: 1px !important;
}
</style>