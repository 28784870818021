import { createRouter, createWebHashHistory } from 'vue-router'
import addBlog from '../views/AddBlog.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: addBlog
  },
]

const router = createRouter({
  // 不用history，用hash
  history: createWebHashHistory(),  
  routes
})

export default router
